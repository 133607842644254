import React from 'react'
import SEO from '../components/seo'

const IndexPage = () => (
	<>
		<SEO title="Home" />
	</>
)

export default IndexPage
